import { Dimensions, StyleSheet } from 'react-native'

import {
  Colors,
  Fonts,
  useTheme
} from '@vfgroup-oneplatform/foundation/Components/Themes'

const styles = StyleSheet.create({
  bannerContainer: {
    marginTop: 20,
    marginHorizontal: Dimensions.get('window').width < 700 && 12
  },
  bannerIcon: {
    resizeMode: 'contain'
  },
  bannerText: {
    fontSize: Fonts.size.regularSmall
  },
  bannerDescription: { fontSize: Fonts.size.regularSmall + 0.5 },
  infoContainer: {
    marginBottom: 32
  },
  boldTitle: {
    fontSize: Fonts.size.h4 - 2,
    ...Fonts.type.bold,
    paddingHorizontal: 20
    // color: Colors.dark_grey
    // marginBottom: 16,
  },
  graySubtitle: {
    marginTop: 16,
    fontSize: Fonts.size.regularSmall,
    paddingHorizontal: 20,
    // color: Colors.dark_grey,
    lineHeight: 20
    // marginVertical: 16,
  },
  learnMoreContainer: {
    flex: 1,
    marginBottom: 32
  },
  absolutePosition: {
    position: 'absolute'
  },
  offText: {
    fontSize: Fonts.size.regularSmall,
    paddingRight: 20
  },
  primaryButton: theme => ({
    backgroundColor: theme.colors.cardBackgroundColorFour
    // backgroundColor: 'transparent',
    // borderBottomColor: 'white',
    // borderBottomWidth: 1,
    // alignItems: 'flex-start',
    // justifyContent: 'flex-start',
    // width: 100,
    // paddingTop: 12,
    // paddingLeft: 0,
    // paddingRight: 0,
    // padding: 0,
    // borderRadius: 0
  }),
  primaryButtonText: theme => ({
    color: theme.colors.textColor
    // padding: 0, color: 'white', margin: 0
  }),
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  marginTop30: {
    marginTop: 30
  },
  modalIconContainer: {
    marginTop: 27
  },
  modalIcon: {
    width: '100%',
    justifySelf: 'center',
    resizeMode: 'contain'
  },
  modalInfoContainer: {
    marginTop: 40
  },
  moreOptions: {
    marginTop: -20,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    // backgroundColor: isDark ? 'black' : 'white',
    shadowRadius: 2
  },
  modalText: {
    fontSize: Fonts.size.h5,
    textAlign: 'center'
  },
  modalButton: {
    marginTop: 44
  },
  generalToggle: {
    marginBottom: -20
  },

  businessText: {
    paddingLeft: 16,
    color: 'red',
    fontWeight: 'bold',
    fontSize: Fonts.size.regularSmall
  },
  borderTop: {
    marginBottom: -20,
    paddingTop: 15
  },
  borderTop2nd: {
    marginBottom: -20,
    paddingTop: 20
  },
  firstSubToggle: {
    // borderTop: 1,
    marginTop: -10,
    borderTopColor: 'rgba(235, 235, 235, 0.5)',
    borderTopWidth: 1,
    paddingTop: 20,
    marginBottom: -30,
    paddingBottom: 10
    // paddingTop: 15
  },
  link: theme => ({
    width: 'auto',
    fontSize: Fonts.size.input,
    // paddingHorizontal: 20,
    borderBottomColor: theme.colors.showMoreColor,
    borderBottomWidth: 1,
    marginVertical: 16,
    marginLeft: 20,
    color: theme.colors.showMoreColor
    // marginRight: 320,
  }),
  capItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
    paddingBottom: 16
  },
  capInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  capIcon: {
    marginRight: 16
  },
  capText: {
    fontSize: Fonts.size.regular,
    marginBottom: -3
  },
  capMoreText: {
    // marginHorizontal: 30
  },
  capWithBorderTop: theme => ({
    borderTopWidth: 1,
    borderTopColor: theme.colors.borderColorThree,
    paddingTop: 25,
    marginTop: -10
  }),
  loaderContainer: {
    marginTop: 30,
    marginBottom: 30,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loader: {
    height: 80,
    alignSelf: 'center',
    width: 80
  },
  headerStyle: {
    marginHorizontal: 0
  },
  overlayLoader: {
    width: 120
  },
  overlayContainer: theme => ({
    position: 'absolute',
    backgroundColor:
      theme.name === 'light' ? Colors.whiteRGBA(0.4) : Colors.blackRGBA(0.4),
    right: 0,
    left: 0,
    top: -60,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center'
  }),
  loadingText: {
    fontSize: Fonts.size.regular
  },
  orderProgressSubtitle: {
    fontSize: Fonts.size.regularSmall
  }
})

export default styles
