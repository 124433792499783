import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import { JourneyScreen } from '@vfal-lab/rn-vfg-unlimited-home'

import { Dimensions, Platform } from 'react-native'

import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'

import ReduxCache from '../../Utils/ReduxCache'

import {
  validateName,
  validateGroupName,
  formatPhoneNumber,
  validatePhoneNumber,
  validatePersonalNumber,
  validateEmail,
} from '../../Utils'

import { MAPS_API_KEY } from '../../Config'

import {
  getFixAndTVs,
  getATLBundles,
  getPegaBundles,
  getTariffPlans,
  getMemberSlots,
  createCustomer,
  getCoveredAreas,
  validateLocation,
  checkCustomerByID,
  unlimitedCheckout,
  getCustomBundlePrice,
  checkCustomerByMsisdn,
  validateUnlimitedNumber
} from '../../Services/Unlimited'

const getInstallDates = () => {
  const calendar = {}
  for (let i = 1; i < 7; i++) {
    const date = moment().add(i, 'day')
    calendar[date.format('YYYY-MM-DD')] = [
      '9am - 12pm',
      '12pm - 3pm',
      '3pm - 5pm'
    ]
  }
  return calendar
}

const UnlimitedHomeJourney = ({ navigation }) => {
  const hasInstallation = true
  const installCalendar = getInstallDates()
  const language = ReduxCache.getLanguage()
  const loggedInNumber = ReduxCache.getMsisdn()

  const onClose = () => navigation.navigate('Dashboard')

  if (Platform.OS === 'web' && Dimensions.get('window').width > 700) {
    return (
      <VFScreen
        title={'Unlimited Home'}
        hideMainHeader
        isFullHeight
      >
        <JourneyScreen
          // GENERAL
          onClose={onClose}
          language={language}
          mapAPIKey={MAPS_API_KEY}
          loggedInNumber={loggedInNumber}
          hasInstallation={hasInstallation}
          // Bundles
          getATLBundles={getATLBundles}
          getPegaBundles={getPegaBundles}
          // Custom Bundles
          getFixAndTVs={getFixAndTVs}
          getTariffPlans={getTariffPlans}
          getMemberSlots={getMemberSlots}
          getCustomBundlePrice={getCustomBundlePrice}
          // Location
          validateLocation={validateLocation}
          getCoveredAreas={getCoveredAreas}
          // Id
          validateName={validateName}
          createCustomer={createCustomer}
          checkCustomerByID={checkCustomerByID}
          checkCustomerByMSISDN={checkCustomerByMsisdn}
          validatePersonalNumber={validatePersonalNumber}
          // Group
          validateGroupName={validateGroupName}
          formatPhoneNumber={formatPhoneNumber}
          validateMember={validateUnlimitedNumber}
          validatePhoneNumber={validatePhoneNumber}
          // Installation
          installCalendar={installCalendar}
          validateEmail={validateEmail}
          // Summary
          onCheckout={unlimitedCheckout}
        />
      </VFScreen>
    )
  }

  return (
    <JourneyScreen
      // GENERAL
      onClose={onClose}
      language={language}
      mapAPIKey={MAPS_API_KEY}
      loggedInNumber={loggedInNumber}
      hasInstallation={hasInstallation}
      // Bundles
      getATLBundles={getATLBundles}
      getPegaBundles={getPegaBundles}
      // Custom Bundles
      getFixAndTVs={getFixAndTVs}
      getTariffPlans={getTariffPlans}
      getMemberSlots={getMemberSlots}
      getCustomBundlePrice={getCustomBundlePrice}
      validateEmail={validateEmail}
      // Location
      validateLocation={validateLocation}
      getCoveredAreas={getCoveredAreas}
      // Id
      validateName={validateName}
      createCustomer={createCustomer}
      checkCustomerByID={checkCustomerByID}
      checkCustomerByMSISDN={checkCustomerByMsisdn}
      validatePersonalNumber={validatePersonalNumber}
      // Group
      validateGroupName={validateGroupName}
      formatPhoneNumber={formatPhoneNumber}
      validateMember={validateUnlimitedNumber}
      validatePhoneNumber={validatePhoneNumber}
      // Installation
      installCalendar={installCalendar}
      // Summary
      onCheckout={unlimitedCheckout}
    />
  )
}

UnlimitedHomeJourney.propTypes = {
  navigation: PropTypes.object
}

export default UnlimitedHomeJourney
