import React from 'react'
import PropTypes from 'prop-types'

import { GroupScreen } from '@vfal-lab/rn-vfg-unlimited-home'

import { VFScreen } from '@vfgroup-oneplatform/foundation/Components'

import ReduxCache from '../../Utils/ReduxCache'

import {
  validateGroupName,
  formatPhoneNumber,
  validatePhoneNumber
} from '../../Utils'

import {
  leaveGroup,
  addGroupMember,
  getGroupMembers,
  getGroupContract,
  removeGroupMember
} from '../../Services'

const GroupManagement = ({ navigation }) => {
  const navigateToScreen = screenName => navigation.navigate(screenName)

  const loggedInNumber = ReduxCache.getMsisdn()

  return (
    <VFScreen title={'Unlimited Home'} hideMainHeader isFullHeight>
      <GroupScreen
        leaveGroup={leaveGroup}
        addMember={addGroupMember}
        loggedInNumber={loggedInNumber}
        removeMember={removeGroupMember}
        getGroupMembers={getGroupMembers}
        nameValidator={validateGroupName}
        getGroupContract={getGroupContract}
        navigateToScreen={navigateToScreen}
        numberFormatter={formatPhoneNumber}
        numberValidator={validatePhoneNumber}
      />
    </VFScreen>
  )
}

GroupManagement.propTypes = {
  navigation: PropTypes.object
}

export default GroupManagement
