import { Image } from 'react-native'

import DarkFrameworkImages from '@vfgroup-oneplatform/framework/Themes/Modes/Dark/Images'

import LightImages from '../Light/Images'

import Images from '../../Images'

const images = {
  ...LightImages,
  ...DarkFrameworkImages,

  // Dashboard
  ic_store: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/ic_Vstore_white.png')
  ),
  icAdd: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icAddWhite.png')
  ),
  icShoppingTrolley: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icShoppingTrolleyWhite.png')
  ),
  icEngineer: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icEngineerWhite.png')
  ),
  ic_settings: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icSettingsWhite.png')
  ),
  ic_globe: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icInternetGlobeCountryWhite.png')
  ),
  al_al_roaming: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/al_al_roaming.png')
  ),
  ic_acceleration: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dashboard/icAccelerationWhite.png')
  ),
  secondary_card2: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dark/Dashboard/secondarycard2.png')
  ),
  ic_data_sharing: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dark/Dashboard/dataSharing.png')
  ),
  ic_sms_text: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dark/Dashboard/smsText.png')
  ),
  ic_call_log: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dark/Dashboard/callLog.png')
  ),
  cluster: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dark/Dashboard/cluster.png')
  ),

  // Addons
  icMenu: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/icMenu.png')
  ),
  icActivity: Image.resolveAssetSource(
    require('./../../../Assets/Images/Addons/icActivity.png')
  ),
  roaming: Image.resolveAssetSource(
    require('./../../../Assets/Images/Dark/Addons/roaming.png')
  ),
  icBillReport: Image.resolveAssetSource(
    require('@vfgroup-oneplatform/foundation/Components/Assets/Images/Icons/icBillReportGenericTariffPlanWhite.png')
  ),
  icClock: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/icClockWhite.png')
  ),
  icMobile: Image.resolveAssetSource(
    require('@vfgroup-oneplatform/foundation/Components/Assets/Images/Icons/ic_mobileDark.png')
  ),
  icRefresh: Image.resolveAssetSource(
    require('../../../Assets/Images/Addons/icSyncWhite.png')
  ),
  ic_outgoing_call: Image.resolveAssetSource(
    require('../../../Assets/Images/Dark/Balance/icOutgoingCallWhite.png')
  ),
  ic_social_pass: Image.resolveAssetSource(
    require('../../../Assets/Images/Balance/icSocialPass.png')
  ),
  icMail: Image.resolveAssetSource(
    require('../../../Assets/Images/Dark/Dashboard/icMailWhite.png')
  ),
  icTopUp: Image.resolveAssetSource(
    require('../../../Assets/Images/Dark/Dashboard/icTopUpRed.png')
  ),
  my_plan_calls: Image.resolveAssetSource(
    require('../../../Assets/Images/Addons/icCallsLogWhite.png')
  ),
  my_plan_dataSharing: Image.resolveAssetSource(
    require('../../../Assets/Images/Addons/dataSharingWhite.png')
  ),
  my_plan_smsText: Image.resolveAssetSource(
    require('../../../Assets/Images/Addons/smsTextWhite.png')
  ),
  busniess_usage: Images.busniess_usage_white,

  // Billing
  ic_parentalControl: Image.resolveAssetSource(
    require('../../../Assets/Images/Billing/icParentalControlRed.png')
  ),
  ic_router: Image.resolveAssetSource(
    require('../../../Assets/Images/Billing/icRouterWhite.png')
  ),
  ic_mobile: Image.resolveAssetSource(
    require('../../../Assets/Images/Billing/icMobileWhite.png')
  ),
  icLogoWithCircle: Image.resolveAssetSource(
    require('../../../Assets/Images/Logo/whiteWithCircle.png')
  ),
  ic_indicators_admin: Image.resolveAssetSource(
    require('../../../Assets/Images/Dark/SwitchAccount/icAdmin.png')
  ),
  ic_indicators_business: Image.resolveAssetSource(
    require('../../../Assets/Images/Dark/SwitchAccount/iconsIndicatorsSystemIconsBusinessFfffff.png')
  ),
  ic_indicators_home: Image.resolveAssetSource(
    require('../../../Assets/Images/Dark/SwitchAccount/icHomeWhite@3x.png')
  ),
  ic_hardware_repaire: Image.resolveAssetSource(
    require('../../../Assets/Images/BookAnAppointment/hardware_repair_dark.png')
  ),
  ic_health_check: Image.resolveAssetSource(
    require('../../../Assets/Images/BookAnAppointment/health_check_dark.png')
  ),
  ic_product_showcase: Image.resolveAssetSource(
    require('../../../Assets/Images/BookAnAppointment/product_showcase_dark.png')
  ),

  // DeveloperSettings
  ic_car: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icCarDark.png')
  ),
  ic_dashboard: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icDashboardDark.png')
  ),
  id_document: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icDocumentDark.png')
  ),
  ic_family: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icFamilyDark.png')
  ),
  ic_chevron_down: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icChevronDownWhite.png')
  ),
  community_or_foundation: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/communityOrFoundationDark.png')
  ),
  ic_password_key: Image.resolveAssetSource(
    require('../../../Assets/Images/DeveloperSettings/icPasswordKeyDark.png')
  ),

  // tobi
  ic_clock: Image.resolveAssetSource(
    require('../../../Assets/Images/tobi/ic-clock-orange.png')
  ),

  // settings
  settings_icDashboard: Image.resolveAssetSource(
    require('../../../Assets/Images/Settings/icDashboardWhite.png')
  ),
  settings_usefulInfo: Image.resolveAssetSource(
    require('../../../Assets/Images/Settings/usefulInfoWhite.png')
  ),

  // Roaming Settings
  ic_roamingLocation: Image.resolveAssetSource(
    require('../../../Assets/Images/Settings/ic_roaming_location.png')
  ),
  ic_roamingRedTail: Image.resolveAssetSource(
    require('../../../Assets/Images/Settings/ic_roaming_redTail.png')
  ),

  //Purchase
  icSuperwifi: Image.resolveAssetSource(
    require('../../../Assets/Images/Dark/Purchase/icSuperwifi.png')
  ),
  icPrivacy: Image.resolveAssetSource(
    require('../../../Assets/Images/Dark/Purchase/icPrivacy.png')
  ),

  // Soho Business overview
  icLandlineOrCallMinutes: Image.resolveAssetSource(
    require('../../../Assets/Images/Soho/BusinessOverview/Plans/icLandlineOrCallMinutes.png')
  ),

  // Languages
  icBritishFlag: Image.resolveAssetSource(
    require('../../../Assets/Images/icons_indicators_flags_britain.png')
  ),
  icAlbanianFlag: Image.resolveAssetSource(
    require('../../../Assets/Images/icons_indicators_flags_albanian.png')
  ),
  icHungaryFlag: Image.resolveAssetSource(
    require('../../../Assets/Images/iconsIndicatorsFlagsHungary.png')
  ),

  //edit tertiary cards
  icAllRewards: Image.resolveAssetSource(
    require('../../../Assets/Images/icAllRewardsLight.png')
  ),
  icWifiBroadband: Image.resolveAssetSource(
    require('../../../Assets/Images/icWifiBroadbandLight.png')
  ),
  networkSignal: Image.resolveAssetSource(
    require('../../../Assets/Images/Network-signal-ffffff.png')
  ),
  icShoppingTrolley: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/icShoppingTrolleyWhite.png')
  ),
  ic_acceleration: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/icAccelerationWhite.png')
  ),

  ic_fixed_line: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/fixLineWhite.png')
  ),
  ic_red_box: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/flowerWhite.png')
  ),
  ic_stars: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/ic_starsWhite.png')
  ),
  ic_rewards: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/rewardWhite.png')
  ),
  ic_recharge: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/top_up_white.png')
  ),
  ic_recharge_other: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/top-up-another-white.png')
  ),
  ic_parking: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/parkingWhite.png')
  ),
  ic_sharing: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/sharingWhite.png')
  ),
  ic_call_me_back: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/call-me-back-white.png')
  ),
  ic_ticket: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/ic_ticket_white.png')
  ),
  ic_broadband: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/wifi_medium_white.png')
  ),
  ic_tv_icon: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/tv_white.png')
  ),
  icRoaming: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/iconsIndicatorsSystemIconsRoamingWhite.png')
  ),
  ic_shake: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/shake_white.png')
  ),
  ic_doc: Image.resolveAssetSource(
    require('../../../Assets/Images/Settings/ic_doc_white.png')
  ),
  ic_phone: Image.resolveAssetSource(
    require('../../../Assets/Images/Settings/ic_phone_white.png')
  ),
  ic_credit: Image.resolveAssetSource(
    require('../../../Assets/Images/Billing/iconsIndicatorsSystemIconsAutoTopUpFfffff.png')
  ),

  ic_entertainment: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/ic_entertainment_light.png')
  ),

  ic_entertainment: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/ic_entertainment_light.png')
  ),
  speed: Images.speed_white,
  payment: Images.payment,
  payment: Images.payment,
  ic_soho_offers: DarkFrameworkImages.ic_rewards,
  call_log: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/call-log-white.png')
  ),
  ic_store: Image.resolveAssetSource(
    require('../../../Assets/Images/Dashboard/ic_store_white.png')
  ),
  cloud: Images.cloud_white,
  digital: Images.digital_white,
  fixed: Images.fixed_white,
  security: Images.security_white,
  vhub: Images.vhub_white,
  soho_usage_tile_bg: Images.soho_usage_tile_bg_dark,
  ic_bundle: Images.ic_bundle_white,
  usage_tile_bg: Images.usage_tile_bg_dark,
  'bookcalendar-month': Images['bookcalendar-month-dark'],
  bundles: Images.bundles_dark,
  'bookcalendar-month': Images['bookcalendar-month-dark']
}

export default images
