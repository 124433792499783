import axios from 'axios'

import { isFunction } from 'lodash'

import { retrieveUtilities } from '@vfgroup-oneplatform/foundation/Utils/ServicesUtils/Utilities'

import { API_URLS } from '../Config'

import ReduxCache from '../../Utils/ReduxCache'

import {
  errorMapper,
  memberResponseMapper,
  contractResponseMapper,
  addMemberResponseMapper,
  removeMemberResponseMapper
} from './Mappers'

export const getGroupMembers = async utilities => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper: errorMapper,
    responseMapper: memberResponseMapper
  })
  try {
    _utilities.onStart()
    const response = await axios.get(API_URLS.unlimited.getMembers, {
      headers: {
        'vf-project': 'unlimited'
      },
      params: {
        id: ReduxCache.getMsisdn(),
        'characteristic.value': 'inGroup'
      }
    })
    const _response = _utilities.responseMapper(response?.data ?? [])
    _utilities.onSuccess(_response)
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

export const getGroupContract = async utilities => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper: errorMapper,
    responseMapper: contractResponseMapper
  })
  try {
    _utilities.onStart()
    const response = await axios.get(
      `${API_URLS.unlimited.getContract}/${ReduxCache.getMsisdn()}`,
      {
        headers: {
          'vf-project': 'unlimited'
        }
      }
    )
    const _response = _utilities.responseMapper(response.data)
    _utilities.onSuccess(_response)
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError(_error)
    } else {
      throw _error
    }
  }
}

export const addGroupMember = async ({ body, ...utilities }) => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper: errorMapper,
    responseMapper: addMemberResponseMapper
  })
  try {
    _utilities.onStart()
    const response = await axios.post(
      API_URLS.unlimited.createCustomer,
      {
        status: 'validated',
        familyName: 'string',
        givenName: 'string',
        partyCharacteristic: [
          {
            name: 'tariffPlan',
            value: body.tariffPlan
          }
        ],
        relatedParty: [
          {
            id: body.number,
            '@referredType': 'member'
          },
          {
            id: body.admin,
            '@referredType': 'admin'
          }
        ]
      },
      {
        headers: {
          'vf-project': 'unlimited'
        }
      }
    )
    const _response = _utilities.responseMapper(response?.data ?? [])
    _utilities.onSuccess({ response: _response, number: body.number })
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError({ message: _error, number: body.number })
    } else {
      throw _error
    }
  }
}

export const removeGroupMember = async ({ body, ...utilities }) => {
  const _utilities = retrieveUtilities({
    ...utilities,
    errorMapper: errorMapper,
    responseMapper: removeMemberResponseMapper
  })
  try {
    _utilities.onStart()
    const response = await axios.delete(
      `${API_URLS.unlimited.createCustomer}/${body.number}`,
      {
        headers: {
          'vf-project': 'unlimited'
        }
      }
    )
    const _response = _utilities.responseMapper(response?.data ?? [])

    _utilities.onSuccess({ ..._response, ...body })
  } catch (error) {
    const _error = _utilities.errorMapper(error)
    if (isFunction(_utilities.onError)) {
      _utilities.onError({ message: _error, number: body.number })
    } else {
      throw _error
    }
  }
}

export const leaveGroup = utilities => {
  const _utilities = retrieveUtilities(utilities)
  try {
    _utilities.onStart()
  } catch (err) {
    _utilities.onError()
  }
}
